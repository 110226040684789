<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main">
        <div class="back-button-wrapper" @click="onClickClose">
            <i class="back-button material-icons">{{ options.icon || 'chevron_left' }} </i>
            <!-- <i class="back-button material-icons">{{ options.icon || 'chevron_left' }} </i> -->
            <span v-if="options.headerTitle" class="f-17 f-bold m-l-12 c-black">{{ options.headerTitle }}</span>
        </div>
        <!-- <div class="set-title-box"> -->
        <div class="title f-22 p-l-20" v-html="'멤버십 관련 문의하기 '" />
        <div class="description f-13 p-l-20 p-r-20">
            - 궁금하신 점을 남겨주세요. 고객센터에서 확인 후 연락드리겠습니다 :)
        </div>

        <div class="story-content m-t-12 m-b-0 m-l-20 m-r-20">
            <TextareaWithX
                :textareaRows="rowCount"
                :placeholder="placeholder"
                v-model="inputContent"
                :ref="'comment'"
                :setClass="'content-area m-0'"
            />
        </div>
        <div class="save-story">
            <button class="save-button" @click="onClickSave">답변 저장하기</button>
        </div>
    </div>
</template>

<script>
import inquiryService from '@/services/inquiry'

export default {
    name: 'ModalInquiryWriter',
    props: ['options'],
    data: () => ({
        showSearch: true,
        buttonDisabled: false,
        inputContent: '',
        rowCount: 4,
        changedSelVal: null,
        selectedVal: null,
        title: '',
    }),
    mounted() {
        this.setInputContent()
    },
    computed: {
        essentialFunnel() {
            const fullPath = this.$route.fullPath.split('/')
            return fullPath.includes('signup')
        },
        section() {
            return this.$sections()
        },
        placeholder() {
            return '문의 사항을 남겨주세요 :)'
        },
        disabled() {
            if (this.inputContent.length > 0) {
                return false
            } else {
                return true
            }
        },
    },
    methods: {
        setTitle() {
            if (this.options.userPersonalStory) {
                this.title = this.options.stories[this.options.userPersonalStory.personal_story_id - 1].name
            } else {
                this.title = this.options.storyTitle
            }
        },
        setInputContent() {
            if (this.options.story) {
                this.inputContent = this.options.userPersonalStory.content
            } else if (this.options.mode && this.options.mode === 'modify') {
                this.inputContent = this.options.userPersonalStory.content
            }
        },
        async onClickClose() {
            this.$emit('close')
        },
        async onClickSave() {
            try {
                if (this.inputContent.trim() === '') {
                    this.inputContent = ''
                    this.$modal.basic({
                        body: '문의 내용을 입력해주세요.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    const payload = {
                        user_id: this.$store.getters.me.id,
                        category: this.options.product === 'mf_3month' ? 'MF' : 'MF+',
                        content: this.inputContent,
                    }

                    this.$loading(true)
                    const res = await inquiryService.post(payload)
                    this.$toast.success(res.msg)
                    this.$emit('close')
                    this.$loading(false)
                }
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.back-button-wrapper {
    position: relative;
    background: #faf0e1;
    justify-content: center;

    i {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 20px;
    }
}

.title {
    color: #181f39;
    margin-top: 24px;
    text-align: left;
}

.description {
    color: #787878;
    line-height: 19.5px;
}

.modify-box {
    img {
        position: absolute;
        float: right;
    }
}

.story-content {
    color: #5c5c77;
    border: 1px #5c5c77;

    textarea {
        font-size: 15px;
        line-height: 22.5px;
    }
}

.write-more {
    color: #5c5c77;
}

.save-story {
    position: absolute;
    padding: 0 20px 0 20px;
    bottom: 16px;
    width: 100%;

    .save-button {
        width: 100%;
        height: 48px;
        border-radius: 36px;
        background-color: #151360;
        color: #f7f7f4;

        &[disabled],
        &.disabled {
            border: none;
            background: #cac4d3;
            box-shadow: none;
            color: #ffffff;
            @include f-medium;
        }
    }
}
</style>
